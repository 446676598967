import React, { useState, useEffect } from "react";

import ReactPaginate from "react-paginate";
import axios from "axios";

import Menu from "./menu";
import Item from "./item";

import IconSearch from "../../assets/search-outline.svg";

const CryptoPoliticos = () => {
  const [coins, setCoins] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  let itemsPerPage = 20;
  const offset = currentPage * itemsPerPage;
  const pageCount = Math.ceil(coins.length / itemsPerPage);

  useEffect(() => {
    const API_URL =
      window.location.hostname === "localhost"
        ? "http://localhost:8080/api/v2"
        : "https://api.paradigma.education/api/v2";
    axios
      .get(`${API_URL}/candidates`)
      .then((res) => setCoins(res.data.data))
      .catch((error) => console.log(error));
  }, []);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  return (
    <>
      <section className="crypto-politicos-container">
        <div className="search-container">
          <input
            type="text"
            placeholder="Busca"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <img alt="icon search" src={IconSearch} />
        </div>

        <table className="crypto-politicos-table">
          <Menu data={coins} setData={setCoins} />
          <Item data={coins} searchValue={searchValue} offset={offset} />
        </table>
        <ReactPaginate
          breakLabel="..."
          previousLabel="<"
          nextLabel=">"
          pageRangeDisplayed={3}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          renderOnZeroPageCount={null}
          containerClassName={
            searchValue
              ? "pagination-wrapper-display-none"
              : "pagination-wrapper"
          }
          pageClassName="pagination-component"
          pageLinkClassName="pagination-text"
          nextLinkClassName="pagination-text"
          previousLinkClassName="pagination-text"
          previousClassName="pagination-component"
          nextClassName="pagination-component"
          breakClassName="pagination-component"
          breakLinkClassName="pagination-text"
        />
      </section>
    </>
  );
};

export default CryptoPoliticos;
