import "./App.css";
import CryptoPoliticos from "./components/crypto-politicos";
import Header from "./components/header";
import Marquee from "./components/crypto-marquee";

function App() {
  return (
    <div className="app page-padding">
      <Marquee />
      <Header />
      <CryptoPoliticos />
    </div>
  );
}

export default App;
