import React, { useState } from "react";

import caretDownOutline from "../../assets/caret-down-outline-1.svg";

const Menu = ({ data, setData }) => {
  const [order, setOrder] = useState("ASC");

  const sort = (type) => {
    let sortedArray;

    if (order === "ASC") {
      if (type === "name") {
        sortedArray = [...data].sort((a, b) =>
          a[type].toLowerCase() > b[type].toLowerCase() ? -1 : 1
        );
      }

      if (type !== "name") {
        sortedArray = [...data].sort((a, b) => (a[type] > b[type] ? -1 : 1));
      }
      setData(sortedArray);
      setOrder("DSC");
    }

    if (order === "DSC") {
      if (type === "name") {
        sortedArray = [...data].sort((a, b) =>
          a[type].toLowerCase() > b[type].toLowerCase() ? 1 : -1
        );
      }

      if (type !== "name") {
        sortedArray = [...data].sort((a, b) => (b[type] > a[type] ? -1 : 1));
      }

      setData(sortedArray);
      setOrder("ASC");
    }
  };

  return (
    <>
      <thead>
        <tr className="menu-row">
          <th onClick={() => sort("name")}>
            Candidato
            <img alt="icon-arrow" src={caretDownOutline} />
          </th>
          <th onClick={() => sort("sigle")}>
            Partido
            <img alt="icon-arrow" src={caretDownOutline} />
          </th>
          <th onClick={() => sort("position")}>
            Cargo
            <img alt="icon-arrow" src={caretDownOutline} />
          </th>
          <th onClick={() => sort("state")}>
            Estado
            <img alt="icon-arrow" src={caretDownOutline} />
          </th>
          <th onClick={() => sort("job")}>
            Ocupação
            <img alt="icon-arrow" src={caretDownOutline} />
          </th>
          <th onClick={() => sort("cryptoQuantity")}>
            Criptomoedas
            <img alt="icon-arrow" src={caretDownOutline} />
          </th>
          <th onClick={() => sort("totalValue")}>
            Valor total
            <img alt="icon-arrow" src={caretDownOutline} />
          </th>
        </tr>
      </thead>
    </>
  );
};

export default Menu;
