import React, { useState, useEffect } from "react";
import axios from "axios";
import Marquee from "react-fast-marquee";

  // Dados mockados simulando a API
  const mockData = [
    { name: "Candidato 1", sigle: "PSDB", cryptoQuantity: true },
    { name: "Candidato 2", sigle: "PT", cryptoQuantity: false },
    { name: "Candidato 3", sigle: "PSDB", cryptoQuantity: true },
    { name: "Candidato 4", sigle: "PDT", cryptoQuantity: true },
    { name: "Candidato 5", sigle: "PSDB", cryptoQuantity: false },
    { name: "Candidato 6", sigle: "PDT", cryptoQuantity: true },
    { name: "Candidato 7", sigle: "PSDB", cryptoQuantity: true },
    { name: "Candidato 8", sigle: "PSDB", cryptoQuantity: true },
    { name: "Candidato 9", sigle: "PSDB", cryptoQuantity: true },
    { name: "Candidato 10", sigle: "PSDB", cryptoQuantity: true },
  ];

const CryptoMarquee = () => {
  const [percentageCripto, setPercentageCripto] = useState(null);
  const [coiner, setCoiner] = useState("");

  const cryptoPercentage = (data) => {
    const candidatesCrypto = data.filter(c => c.cryptoQuantity);
    return ((candidatesCrypto.length / data.length) * 100).toFixed(1);
  }

  const coinerParty = (data) => {
    const candidatesCrypto = data.filter(c => c.cryptoQuantity);
    const parties = {};
    
    candidatesCrypto.forEach(c => parties[c.sigle] = (parties[c.sigle] || 0) + 1);
    return Object.keys(parties).reduce((a, b) => parties[a] > parties[b] ? a : b);
  }

  useEffect(() => {
    const API_URL =
      window.location.hostname === "localhost"
        ? "http://localhost:8080/api/v2"
        : "https://api.paradigma.education/api/v2";
    axios
      .get(`${API_URL}/candidates`)
      .then((res) => {
        setPercentageCripto(cryptoPercentage(res.data.data));
        setCoiner(coinerParty(res.data.data));
      })
      .catch((error) => console.log(error));
      setPercentageCripto(cryptoPercentage(mockData));
      setCoiner(coinerParty(mockData));
  }, []);

  return (
    <Marquee gradient={false} speed={50} autoFill={true}>
      <div className="marquee-item">
        Quantos com cripto: 
        <span className="info-bg">{percentageCripto}%</span>
      </div>

      <div className="marquee-item">
        Partido mais Coiner: 
        <span className="info-bg">{coiner}</span>
      </div>
    </Marquee>
  );
};

export default CryptoMarquee;